export class AppConsts {
  //static remoteServiceBaseUrl: string = "https://server.meekou.cn";
  // static appBaseUrl: string = "https://excel.meekou.cn";
  // static functionApiBaseUrl: string = "https://addin.meekou.cn";
  // static Env: string = "development";
  static isDev : boolean = true;
  static ClientEnv: string = "development";
  static ServerEnv: string = "development";
  static AzureRegisterAppEnv: string = "development";

  static appBaseUrl: string = AppConsts.ClientEnv === 'development' ? "http://localhost:3000" : `${location.protocol}//${location.hostname + (location.port ? ":" + location.port : "")}`;
  static remoteServiceBaseUrl: string = AppConsts.ServerEnv === 'development' ? "https://localhost:44311":"https://server.meekou.cn";
  static functionApiBaseUrl: string = AppConsts.ServerEnv === 'development' ? "http://localhost:7060" : `${location.protocol}//${location.hostname + (location.port ? ":" + location.port : "")}`;

  static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
  static Meekou: string = "Meekou";
  static AccessToken: string = "AccessToken";
  static DataFromWeb: string = "data-from-web";
  static DataFromImg: string = "data-from-img";
  static LoginDialog: string = "login-dialog";  
  static MessageDialog: string = "message-dialog";
  static MoveColumnDialog: string = "move-column-dialog";
  static TocDialog: string = "toc-dialog";
  static MergeWorkSheetsDialog: string = "merge-worksheets-dialog";
  static ClearFormatDialog: string = "clear-format-dialog";
  static ErrorDialog: string = "error-dialog";
  static Code: string = "code";

  //#region AuthConfig
  static ClientId: string = this.AzureRegisterAppEnv === 'development' ? "1b56f160-8073-441a-958c-cabd5a92c33a":"22a9f72e-a2ba-4d8b-8dce-1c3e6c279908";
  //#endregion
  static localeMappings: any = [];

  static readonly userManagement = {
    defaultAdminUserName: "admin",
  };

  static readonly localization = {
    defaultLocalizationSourceName: "Mango",
  };

  static readonly authorization = {
    encryptedAuthTokenName: "enc_auth_token",
  };
}
